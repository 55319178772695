import { BaseEnvironment } from './baseEnvironment.interface';

export const baseEnvironment: BaseEnvironment = {
  production: false,

  authenticationUrl: 'https://auth.datalean-nodejs-preprod.catalean.com/',
  usersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/',
  surveyUrl: 'https://survey.datalean-nodejs-preprod.catalean.com/survey/',
  surveyResponseUrl: 'https://survey.datalean-nodejs-preprod.catalean.com/response/',
  groupsUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/applicationGroup/',
  smartGroupsUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/applicationSmartGroup/',
  contestUrl: 'https://contest.datalean-nodejs-preprod.catalean.com/contest/',
  tableUsersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/smartcommunity/',
  applicationUsersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/application/',
  productUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/product/',
  // productUrl: 'http://localhost:3006/product/',
  communicationUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/communication/',
  // communicationUrl: 'http://localhost:3006/communication/',
  communityUrl: 'https://community.datalean-nodejs-preprod.catalean.com/community/',
  // communityUrl: 'http://localhost:3009/community/',
  mediaLibraryUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/media-library/',
  // mediaLibraryUrl: 'http://localhost:3013/media-library/',
  structureUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structure/',
  structureFieldUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structureField/',
  filterUrl: 'https://filter.datalean-nodejs-preprod.catalean.com/filter/',
  featureValueUrl: 'https://feature.datalean-nodejs-preprod.catalean.com/featureValue/',
  organizationUrl: 'https://organization.datalean-nodejs-preprod.catalean.com/organization/',
  configurationUrl: 'https://configuration.datalean-nodejs-preprod.catalean.com/config/',
  rolesUrl: ' https://user.datalean-nodejs-preprod.catalean.com/role/',
  permissionUrl: ' https://user.datalean-nodejs-preprod.catalean.com/permission/',
  userStructureUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/userstructure/',
  wishlistUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/wishlist/',
  templateUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/template',
  integrationUrl: 'https://configuration.datalean-nodejs-preprod.catalean.com/integration/',
  contentUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/content/',
  resourceUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/resource/',
  availabilityRuleUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/availability-rule/',
  bookableProductUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/product/',
  reservationsUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/reservation/',
  lessonUrl: 'https://lms.datalean-nodejs-preprod.catalean.com/lesson/',
  // resourceUrl: 'http://localhost:3016/resource/',
  // availabilityRuleUrl: 'http://localhost:3016/availability-rule/',
  // bookableProductUrl: 'http://localhost:3016/product/',
  // reservationsUrl: 'http://localhost:3016/reservation/',

  orderUrl: 'https://dataleandev.catalean.com/order/',
  gstorageUrl: 'https://dataleandev.catalean.com/gstorage/',
  feedbackUrl: 'https://datalean.catalean.com/generalfeedback/feedback/',
  bookableResourceUrl: 'https://dataleandev.catalean.com/booking/',
  changelogUrl: 'https://dataleandev.catalean.com/changelog/',
  entityChangelogUrl: 'https://dataleandev.catalean.com/entityChangelog/',
  privacyUrl: 'https://addiction.it/privacy-policy/',
  conditionTermUrl: 'https://addiction.it/privacy-policy/',
  tableStructureUUID: '',
  cataleanUrl: '',
  orgLogoUrl: '',

  version: '2.2.6',
};
